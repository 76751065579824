<template>
  <div class="auth-second-step second-step">
    <p class="second-step__text">{{ $t('confirmation-code-sent', {email: getAuthForm.email}) }}</p>
    <div class="second-step__form">
      <ui-input
          v-model="getAuthForm.authCode"
          :label="$t('code')"
          required-field
          @input="authCodeError = false"
          :error="$v.getAuthForm.authCode.$anyError || authCodeError"
          :error-text="getAuthCodeErrorText"
          v-mask="'#####'"
      />
    </div>
    <div class="second-step__action">
      <ui-button
          :disabled="disabledCodeButton"
          color="outline"
          @click="resendCode"
      >
        {{ $t('no-code') }}
      </ui-button>
      <ui-button
          @click="submit"
          :disabled="$v.getAuthForm.$anyError || !getAuthForm.authCode"
      >
        {{ $t('continue') }}
      </ui-button>
    </div>

    <ui-alert v-model="visibleErrorAlert">
      <h5 class="ui-alert__title">
        {{ alertErrorText }}
      </h5>
      <UiButton color="error" @click="visibleErrorAlert = false">
        {{ $t('okay') }}
      </UiButton>
    </ui-alert>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {minLength, required} from "vuelidate/lib/validators";
import {setCookie} from "@/utils/cookie";
export default {
  name: 'AuthInSecondStep',
  components: {
    UiAlert: () => import("@/components/ui/UiAlert"),
    UiInput: () => import('@/components/ui/UiInput'),
    UiButton: () => import('@/components/ui/UiButton')
  },

  data() {
    return {
      authCodeError: false,
      disabledCodeButton: false,
      visibleErrorAlert: false,
      alertErrorText: ''
    }
  },

  validations() {
    return {
      getAuthForm: {
        authCode: {
          required,
          minLength: minLength(5),
        },
      }
    }
  },

  methods: {
    ...mapActions([
       'reqAuth'
    ]),
    submit() {
      this.$v.$touch()
      if (!this.$v.getAuthForm.$anyError) {
        this.reqAuth({
          "email": this.getAuthForm.email,
          "password": this.getAuthForm.password,
          'authCode': this.getAuthForm.authCode,
        }).then((res) => {
          setCookie('authToken', res.data.token)
          localStorage.setItem('registerStep', '2')
          this.$emit('success')
        }).catch((err) => {
          if(err.response.data.message === 'WRONG_AUTH_CODE') {
            this.authCodeError = true
          }
        })
      }
    },
    resendCode() {
      this.disabledCodeButton = true;
      this.reqAuth({
        "email": this.getAuthForm.email,
      }).catch((err) => {
        this.alertErrorText = err.response.data.message;
        this.visibleErrorAlert = true
      }).finally(() => {
        this.disabledCodeButton = false;
      })
    },
    enter(e) {
      if(e.keyCode === 13) {
        this.submit()
      }
    }
  },

  mounted() {
    document.addEventListener('keydown', this.enter)
  },

  computed: {
    ...mapGetters([
      'getAuthForm'
    ]),
    getAuthCodeErrorText() {
      return (!this.$v.getAuthForm.authCode.required || !this.$v.getAuthForm.authCode.minLength) ? this.$t('code-must-be-5') : '' ||
      this.authCodeError ? this.$t('error-code') : ''
    },
  }
}
</script>

<style lang="scss" scoped>
.second-step {
  width: 100%;
  margin-top: 15px;

  &__text {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #343432;
    margin-bottom: 44px;
  }

  &__form {
    padding-bottom: 20px;
    margin-bottom: 30px;
    border-bottom: 1px solid #F5F5F5;
  }

  &__action {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}
</style>
